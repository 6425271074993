import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import PageTemplateContainer from 'src/containers/common/PageTemplateContainer';
import ListWrapper from 'src/components/list/ListWrapper';
import PostList from 'src/components/list/PostList';

class TagRoute extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const posts = data.allMarkdownRemark.edges.map(({ node }) => ({
      id: node.id,
      excerpt: node.excerpt,
      slug: node.fields.slug,
      title: node.frontmatter.title,
      date: node.frontmatter.date,
      description: node.frontmatter.description,
      tags: node.frontmatter.tags,
      image: node.frontmatter.image,
    }));
    const { tag } = pageContext;
    const { title } = data.site.siteMetadata;
    const { totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`;

    return (
      <PageTemplateContainer>
        <Helmet>
          <title>{`${tag} | ${title}`}</title>
        </Helmet>
        <ListWrapper>
          <h3
            style={{
              marginTop: '1rem',
              marginBottom: '1rem',
              fontSize: '1.5rem',
              fontWeight: '900',
            }}
          >
            {tagHeader}
          </h3>
          <PostList posts={posts} />
        </ListWrapper>
      </PageTemplateContainer>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
